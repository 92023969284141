import React from "react";

import logo from "assets/logo.png";
import { ReactComponent as Instagram } from "assets/instagram.svg";
import { Link, useLocation } from "react-router-dom";
import useMedia from "../../Hooks/useMedia";

import styles from "./Header.module.css";

const Header = () => {
  const mobile = useMedia("(max-width: 840px)");
  const [mobileMenu, setMobileMenu] = React.useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    setMobileMenu(false);
  }, [pathname]);

  return (
    <header className={styles.header}>
      <div className={`container ${styles.headerContainer}`}>
        <Link to="/">
          <div className={styles.img}>
            <img src={logo} alt="logo" />
          </div>
        </Link>
        {mobile && (
          <button
            className={`${styles.mobileButton} ${
              mobileMenu && styles.mobileButtonActive
            }`}
            aria-label="Menu"
            onClick={() => setMobileMenu(!mobileMenu)}
          ></button>
        )}

        <nav
          className={`${mobile ? styles.navMobile : styles.nav} ${
            mobileMenu && styles.navMobileActive
          }`}
        >
          <ul>
            <Link to="/">
              <li>Página inicial</li>
            </Link>
            <Link to="/sobre" className={styles.sobre}>
              <li>Sobre</li>
            </Link>
          </ul>
        </nav>
        <a
          href="https://www.instagram.com/vocesabialagoas/"
          target="blank"
          className={styles.instagram}
        >
          <Instagram />
          vocesabiaalagoas
        </a>
      </div>
    </header>
  );
};

export default Header;
