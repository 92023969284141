import React, { useEffect, useState, useCallback } from "react";
import styles from "./PhotoGrid.module.css";

const API_URL = `${process.env.REACT_APP_URL}`;

const PhotoGrid = () => {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [limit, setLimit] = useState(9);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/posts?populate=*&pagination[limit]=${limit}&sort[0]=date_published:desc`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(results => {
        const { data } = results;
        setLoading(false);
        setPhotos(data);
        if (data.length < limit) {
          setHasMore(false);
        }
      });
  }, [limit]);

  const handleClick = useCallback(
    () => {
      setLimit(limit + 3);
    },
    [limit],
  );

  return (
    <main className={`container ${styles.mainContainer}`}>
      <div className={styles.photoContent}>
        {photos?.length
          ? photos
              .map((photo, index) => (
                <a target="_blank" key={`${photo.id}-${index}`} href={photo?.attributes?.url} rel="noopener noreferrer">
                  <img title={photo?.attributes?.title} src={photo?.attributes?.image?.data?.attributes?.url} alt={photo?.attributes?.title} />
                </a>
              ))
          : 'Carregando'
        }
      </div>
      <button
        className={`${styles.button} ${loading || !hasMore ? `${styles.desabilitado}` : ""}`}
        disabled={loading || !hasMore}
        onClick={handleClick}
      >
        {loading ? "Carregando postagens..." : 'Carregar mais'}
      </button>
    </main>
  );
};

export default PhotoGrid;
