import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useBanners from "src/Hooks/useBanners";
import Carousel from "src/Components/Carousel/Carousel";
import Banner from "src/Components/Banner/Banner";
import PhotoGrid from "src/Components/PhotoArea/PhotoGrid";
import Head from "src/Components/Helper/Head";
import bg1 from "assets/bg-1.jpg";
import bg2 from "assets/bg-2.jpg";
import bg3 from "assets/bg-3.jpg";

const Home = () => {
  const location = useLocation();
  const { randomItemTop, randomItemBottom } = useBanners();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Head title="Página Inicial" description="Página inicial do site." />
      <Carousel>
        <div className="item">
          <img src={bg1} alt="background-1" />
        </div>
        <div className="item">
          <img src={bg2} alt="background-2" />
        </div>
        <div className="item">
          <img src={bg3} alt="background-3" />
        </div>
      </Carousel>
      {randomItemTop && <Banner src={randomItemTop?.attributes?.image?.data?.attributes?.url} href={randomItemTop?.attributes?.link} />}
      <PhotoGrid />
      {randomItemBottom && <Banner src={randomItemBottom?.attributes?.image?.data?.attributes?.url} href={randomItemBottom?.attributes?.link} />}
    </>
  );
};

export default Home;
