import React, { useState, useEffect, useRef } from "react";
import styles from "./VideoModal.module.css";
import {ReactComponent as Exit } from '../../Assets/exit.svg'
import VideoFile from "../../Assets/video/GERAL.mp4";

export default function VideoModal(isOpen) {
  const [openModal, setOpenModal] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openModal]);

  return (
    <div className={openModal ? styles.overlay : styles.disable}>
      <div className={styles.video_modal}>
        <button onClick={() => setOpenModal(false)} className={styles.exit}>
          <Exit />
        </button>
        <video 
          className={styles.video} 
          autoPlay={true}
          ref={videoRef}
          controls
          muted
        >
          <source src={VideoFile} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}