import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_URL,
})

client.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_TOKEN
    console.log(token)
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default client
