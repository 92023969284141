import { useEffect, useState } from "react"
import client from "src/APIClient/client"

const useFetch = (query, variables) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchBanners = async () => {
      setLoading(true)
      const { data } = await client.request(query, variables)
      setData(data?.data)
      setLoading(false)
    }
    fetchBanners()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {
    data,
    loading
  }
}

export default useFetch
