import React , { useEffect, useState } from 'react'
import styles from "./Modal.module.css";
import {ReactComponent as Exit } from 'assets/exit.svg'
import GET_HOME_BANNERS from 'src/APIClient/actions/getHome';
import useFetch from 'src/Hooks/use-fetch';

function Modal() {
  const filterActive = (banners) => {
    return banners.filter(item => item.ativo && item)
  }
  const [banner, setBanner] = useState({});
  const [open, setOpen] = useState(true)
  const {data, loading} = useFetch(GET_HOME_BANNERS)

  useEffect(()=>{
    async function createRandomBanner(){
      const bannersFloat = data?.voceSabia?.BannerFloat;
      if(bannersFloat){
        const bannerActive = filterActive(bannersFloat)
        const numero = Math.floor(Math.random() * bannerActive.length)
        if(bannerActive[numero]) setBanner({link: bannerActive[numero].link,src: bannerActive[numero].image.url})
      }
    }
    createRandomBanner()
  }, [data])
  if(loading){
    return null
  }
  if(!banner.link){
    return null
  }
  return (
    <div className={open ? styles.modal : styles.disable}>
      <button onClick={()=> setOpen(false)}className={styles.exit}><Exit /></button>
      <a href={banner.link || '#'} target="_blank" rel="noopener noreferrer" title="retire seu boleto">
        <img src={`${process.env.REACT_APP_NORMAL_URL}${banner?.src}`} alt='Imagem de gif '></img>
      </a>
    </div>
  )
}

export default Modal
