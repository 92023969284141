import React from "react";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={`container ${styles.footer}`}>
      <small>© 2020. Todos os direitos reservados.</small>
      <div className={styles.contato}>
        <p>Fale conosco por:</p>
        <strong>vocesabiaalagoas@gmail.com</strong>
      </div>
    </footer>
  );
};

export default Footer;
