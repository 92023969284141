import { useEffect, useState } from "react";
import GET_HOME_BANNERS from "src/APIClient/actions/getHome";
import useFetch from "src/Hooks/use-fetch";

const useBanners = () => {
  const { data, loading } = useFetch(GET_HOME_BANNERS);
  const [randomItemTop, setRandomItemTop] = useState();
  const [randomItemBottom, setRandomItemBottom] = useState();

  const filterBanners = (data) => {
    const containers = {
      top: [],
      bottom: [],
      both: []
    }
    data.forEach((item) => {
      const position = item?.attributes?.position;
      containers[position].push(item);
    });
    return containers;
  };

  useEffect(() => {
    if (!loading && data && !randomItemBottom && !randomItemTop) {
      const { top, bottom, both } = filterBanners(data);
      const bannersTop = [...top, ...both];
      const bannersBottom = [...bottom, ...both];

      if (bannersTop.length > 0) {
        const indexTop = Math.floor(Math.random() * bannersTop.length);
        setRandomItemTop(bannersTop[indexTop]);
      }

      if (bannersBottom.length > 0) {
        const indexBottom = Math.floor(Math.random() * bannersBottom.length);
        setRandomItemBottom(bannersBottom[indexBottom]);
      }

    }
  }, [data, loading, randomItemBottom, randomItemTop]);

  return {
    randomItemTop,
    randomItemBottom,
    loading,
    noData: !(randomItemTop?.length || randomItemBottom?.length),
  };
}

export default useBanners;
