import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Carousel from "src/Components/Carousel/Carousel";
import bgCarousel from "assets/bgCarousel.png";
import Banner from "src/Components/Banner/Banner";
import Head from "src/Components/Helper/Head";

import styles from "./Sobre.module.css";
import useBanners from "src/Hooks/useBanners";

const Sobre = () => {
  const location = useLocation();
  const { randomItemTop, randomItemBottom } = useBanners();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Head title="Sobre" description="Sobre o site." />

      <Carousel>
        <div className="item">
          <img src={bgCarousel} alt="img-carrousel" />
        </div>
        <div className="item">
          <img src={bgCarousel} alt="img-carrousel" />
        </div>
        <div className="item">
          <img src={bgCarousel} alt="img-carrousel" />
        </div>
      </Carousel>

      {randomItemTop && <Banner src={randomItemTop?.attributes?.image?.data?.attributes?.url} href={randomItemTop?.attributes?.link} />}

      <section className="container">
        <h3 className={styles.title}>Você Sabia? Alagoas.</h3>
        <p className={styles.description}>
          Alagoas é uma das 27 unidades federativas do Brasil. Está situado no
          leste da região Nordeste e tem como limites Pernambuco, Sergipe e
          Bahia. Ocupa uma área de 27.778,506 km², sendo ligeiramente maior que
          o Haiti. Sua capital é a cidade de Maceió.
          <br />
          <br /> O blog voltado para curiosidades da nossa terra, Alagoas. A
          intenção é deixar nossos seguidores informados com assuntos
          interessantes e acontecimentos marcantes.⁠⁠⁠⁠
        </p>
      </section>

      {randomItemBottom && <Banner src={randomItemBottom?.attributes?.image?.data?.attributes?.url} href={randomItemBottom?.attributes?.link} />}

    </div>
  );
};

export default Sobre;
