import React from "react";
import styles from "./Carousel.module.css";

const Carousel = ({ children }) => {
  const [active, setActive] = React.useState(0);
  const [position, setPosition] = React.useState(0);
  const contentRef = React.useRef();

  const intervalo = React.useCallback(() => {
    setInterval(() => {
      if (active === children.length - 1) {
        setActive(0);
      } else {
        setActive(active + 1);
      }
    }, 7000);
  }, [active, children.length]);

  React.useEffect(() => {
    const { width } = contentRef.current.getBoundingClientRect();
    setPosition(-(width * active));
  }, [active, intervalo]);

  function slideGoTo(index) {
    setActive(index);
  }

  return (
    <section className={styles.carouselWrapper}>
      <div
        className={styles.content}
        style={{ transform: `translateX(${position}px)` }}
        ref={contentRef}
      >
        {children}
      </div>
      <p>
        Cada nome tem uma história Cada história tem um conteúdo É o que
        produzimos.
      </p>
      <nav className={styles.nav}>
        {children.map((_, index) => {
          return (
            <button
              key={index}
              className={`${styles.ball} ${
                index === active ? `${styles.active}` : ""
              }`}
              onClick={() => slideGoTo(index)}
            >
              Proximo
            </button>
          );
        })}
      </nav>
    </section>
  );
};

export default Carousel;
