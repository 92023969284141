import React from "react";

import styles from "./Banner.module.css";

const Banner = ({ src, href }) => {
  const BannerSrc = src
  const BannerLink = href
  return (
    <section className="container">
      <a href={BannerLink} target="_blank" rel="noopener noreferrer" className={styles.banner}>
        <img style={{ margin: "0 auto" }} src={BannerSrc} alt="banner" width={780} height={98}  />
      </a>
    </section>
  );
};

export default Banner;
